import React, { useRef, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {
  TextField,
  Button,
  Grid,
  Box,
  ButtonGroup,
  Alert,
} from "@mui/material";
import { base_URL, visualise_URL, chat_URL } from "../apiURLs";
import axios from "axios";

// import Spinner from "../components/Spinner";
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import html2canvas from "html2canvas";
import Plotly from "plotly.js-dist";
import { saveAs } from "file-saver"; // For saving files
import { Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../components/theme";
import ContactForm from "../components/ContactForm"; // Import the form component

import "./mainView_styles.css"; // Import the CSS file
import "./mainView_queries.css"; // Import the CSS file
import {
  fetchNonRegisteredUsageTracker,
  updateNonRegisteredUsageTracker,
  updateRegisteredUser,
} from "../service/dataServices";
import LimitReachedDialog from "../components/LimitReachedDialog";
import UserProfileSideBar from "../components/UserProfileSidebar"; // Correct casing
// import PromptSuggestions from "../components/PromptSuggestions";
import ChipScroll from "../components/ChipScroll";
import AuthenticatorDialog from "../components/AuthenticatorDialog";
import { signOut } from "aws-amplify/auth";
import LoadingBar from "../components/LoadingBar";
import LowBalanceDialog from "../components/LowBalanceDialog";
import InstructionsBox from "../components/InstructionsBox";
import IconButton from "@mui/material/IconButton";
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import { LoginOutlined } from "@mui/icons-material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { useNavigate } from "react-router-dom";
import { Hub } from "aws-amplify/utils";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";

const MainView = () => {
  const [prompt, setPrompt] = useState("");
  const navigate = useNavigate();
  const [plot_html, setPlot] = useState(null); // State to store response data
  const textFieldRef = useRef(null); // Create a ref for the TextField
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null); // State for share menu
  const iframeRef = useRef(null);
  const hasRunRef = useRef(false); // Add this line
  const [maxPromptsUnreg] = useState(5);
  const [maxPromptsReg] = useState(10);
  const [limitReachedOpen, setLimitReachedOpen] = useState(false);
  const [lowBalanceOpen, setLowBalanceOpen] = useState(false);
  const [promptsUsed, setPromptsUsed] = useState(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.promptsUsed || 0;
  });
  const [isUserSignedIn, setIsUserSignedIn] = useState(false); // Correctly destructure the state
  const [showAuthenticator, setShowAuthenticator] = useState(false);
  const [authInitialState, setAuthInitialState] = useState(null);
  const [warning, setWarning] = useState(false); // Add this line
  const [agent, setAgent] = useState(1);

  const [loggedInUser, setLoggedInUser] = useState(() => {
    const lastAuthUser = localStorage.getItem(
      "CognitoIdentityServiceProvider.2a1jfbbqun3crbidcnioi1jdgd.LastAuthUser"
    );
    if (lastAuthUser) {
      const signInDetails = JSON.parse(
        localStorage.getItem(
          `CognitoIdentityServiceProvider.2a1jfbbqun3crbidcnioi1jdgd.${lastAuthUser}.signInDetails`
        )
      );
      return {
        username: lastAuthUser,
        userId: lastAuthUser,
        signInDetails: {
          loginId: signInDetails.loginId,
          authFlowType: signInDetails.authFlowType,
        },
      };
    }
    return null;
  });

  // useEffect(() => {
  //   async function handleAuthEvents() {
  //     console.log("Checking user sign-in status...");
  //     try {
  //       const currentUser = await getCurrentUser();
  //       if (currentUser.signInDetails) {
  //         console.log("User is already signed in.");
  //         handleSignIn(currentUser);
  //       } else {
  //         console.log("User is not signed in. Fetching user attributes...");
  //         const attributes = await fetchUserAttributes();
  //         const formattedUserDetails = {
  //           username: currentUser.username,
  //           userId: currentUser.userId,
  //           signInDetails: {
  //             loginId: attributes.email,
  //             authFlowType: "USER_SRP_AUTH",
  //           },
  //         };
  //         handleSignIn(formattedUserDetails);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user info:", error);
  //     }
  //   }

  //   handleAuthEvents();
  // }, []);

  // Hub.listen("auth", (payload) => {
  //   console.log(payload);
  // });

  useEffect(() => {
    const handleAuthEvents = async ({ payload }) => {
      console.log("Received auth event:", payload);
      if (payload.event === "signedIn") {
        console.log("User has been signed in successfully.");
        try {
          let user = await getCurrentUser();
          console.log(user);
          let formattedUserDetails; // Declare the variable here
          if (user.signInDetails) {
            handleSignIn(user);
          } else {
            let attributes = await fetchUserAttributes();
            formattedUserDetails = {
              username: user.username,
              userId: user.userId,
              signInDetails: {
                loginId: attributes.email,
                authFlowType: "USER_SRP_AUTH",
              },
            };
            console.log(formattedUserDetails);
            handleSignIn(formattedUserDetails);
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    const hubListener = Hub.listen("auth", handleAuthEvents);

    return () => {
      hubListener(); // Cleanup the listener when component unmounts
    };
  }, []);

  const [progress, setProgress] = useState(0);
  const [pricePerPrompt] = useState(2.49);
  const [userBalance, setUserBalance] = useState(0); // Initialize with default balance

  const visualise = async () => {
    // const agent = 2;
    const randomValue = Math.floor(Math.random() * 100) + 1;
    setAgent(
      randomValue <= 20
        ? 1
        : randomValue <= 50
        ? 2
        : randomValue <= 70
        ? 3
        : randomValue <= 90
        ? 4
        : 5
    );

    setProgress(0);
    setPlot(null);
    setLoading(true);

    let userId;
    let userObject;
    let maxPrompts;
    let priceCheck = true;
    let userType;

    console.log(isUserSignedIn);

    if (!isUserSignedIn) {
      userObject = JSON.parse(localStorage.getItem("user"));
      userId = userObject.visitorIdentifier || userObject.userId;
      maxPrompts = maxPromptsUnreg;
      userType = "UNREGISTERED";
    } else {
      userId = loggedInUser.userId;
      userObject = await fetchNonRegisteredUsageTracker(userId);
      maxPrompts = maxPromptsReg;
      priceCheck = userObject.balance >= pricePerPrompt;
      userType = "REGISTERED";
    }

    if (isUserSignedIn) {
      if (userObject.promptsUsed >= maxPrompts && !priceCheck) {
        setLoading(false);
        setLowBalanceOpen(true);
        return;
      }
    } else {
      if (userObject.promptsUsed >= maxPrompts) {
        setLoading(false);
        setLimitReachedOpen(true);
        return;
      }
    }

    try {
      const response = await axios.get(
        `${base_URL}/${visualise_URL(prompt, agent)}`,
        {
          headers: {
            "X-User-ID": userId,
            "X-User-Type": userType,
          },
        }
      );
      console.log(response.data);
      setPlot(response.data);

      const updatedUser = await fetchNonRegisteredUsageTracker(userId);
      setPromptsUsed(updatedUser.promptsUsed);

      if (!isUserSignedIn) {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        currentUser.promptsUsed = updatedUser.promptsUsed;
        localStorage.setItem("user", JSON.stringify(currentUser));
      } else {
        setUserBalance(updatedUser.balance);
      }

      setProgress(100);
      setTimeout(() => {}, 2000);
      setLoading(false);
    } catch (error) {
      console.error("There was an error!", error);
      setLoading(false);
    }
  };

  const chatToAgent = async () => {
    const randomValue = Math.floor(Math.random() * 100) + 1;
    setAgent(
      randomValue <= 20
        ? 1
        : randomValue <= 50
        ? 2
        : randomValue <= 70
        ? 3
        : randomValue <= 90
        ? 4
        : 5
    );

    setProgress(0);
    setPlot(null);
    setLoading(true);

    let userId;
    let userObject;
    let maxPrompts;
    let priceCheck = true;
    let userType;

    if (!isUserSignedIn) {
      userObject = JSON.parse(localStorage.getItem("user"));
      userId = userObject.visitorIdentifier || userObject.userId;
      maxPrompts = maxPromptsUnreg;
      userType = "UNREGISTERED";
    } else {
      userId = loggedInUser.userId;
      userObject = await fetchNonRegisteredUsageTracker(userId);
      maxPrompts = maxPromptsReg;
      priceCheck = userObject.balance >= pricePerPrompt;
      userType = "REGISTERED";
    }

    if (isUserSignedIn) {
      if (userObject.promptsUsed >= maxPrompts && !priceCheck) {
        setLoading(false);
        setLowBalanceOpen(true);
        return;
      }
    } else {
      if (userObject.promptsUsed >= maxPrompts) {
        setLoading(false);
        setLimitReachedOpen(true);
        return;
      }
    }

    try {
      const response = await axios.get(
        `${base_URL}/${chat_URL(prompt, agent)}`,
        {
          headers: {
            "X-User-ID": userId,
            "X-User-Type": userType,
          },
        }
      );
      console.log(response.data);
      setPlot(response.data);

      const updatedUser = await fetchNonRegisteredUsageTracker(userId);
      setPromptsUsed(updatedUser.promptsUsed);

      if (!isUserSignedIn) {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        currentUser.promptsUsed = updatedUser.promptsUsed;
        localStorage.setItem("user", JSON.stringify(currentUser));
      } else {
        setUserBalance(updatedUser.balance);
      }

      setProgress(100);
      setTimeout(() => {}, 2000);
      setLoading(false);
    } catch (error) {
      console.error("There was an error!", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loggedInUser !== null) {
      setIsUserSignedIn(true);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (hasRunRef.current) return; // Add this line

    hasRunRef.current = true; // Add this line

    // Focus the TextField when the component mounts
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }

    const initializeUser = async () => {
      let storedUser = localStorage.getItem("user");
      console.log(storedUser);
      if (
        storedUser !== "undefined" &&
        storedUser !== "null" &&
        storedUser !== null &&
        storedUser !== undefined
      ) {
        let userObject = JSON.parse(storedUser);
        let visitorId = userObject.userId || userObject.visitorIdentifier;

        console.log(visitorId);

        const newUser = await fetchNonRegisteredUsageTracker(visitorId);
        localStorage.setItem("user", JSON.stringify(newUser));
      } else {
        console.log("No user data found.");
        updateNonRegisteredUsageTracker();
      }
    };

    initializeUser();
  }, []);

  const openInNewTab = () => {
    if (plot_html) {
      const newWindow = window.open("", "_blank"); // Open a new tab

      // Write the HTML content to the new tab
      newWindow.document.write(plot_html);
      newWindow.document.close(); // Close the document to complete loading
    }
  };

  const downloadAsImage = () => {
    if (iframeRef.current) {
      const iframeDoc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      const plotDiv = iframeDoc.querySelector(".plotly-graph-div");

      if (plotDiv) {
        Plotly.toImage(plotDiv, {
          format: "png",
          height: 1080,
          width: 1920,
        })
          .then((url) => {
            saveAs(url, "plot.png");
          })
          .catch((error) => {
            console.error("Error generating image:", error);
          });
      } else {
        console.error("Plotly graph not found in iframe");
      }
    }
  };

  const downloadAsHtml = () => {
    const link = document.createElement("a");
    const blob = new Blob([plot_html], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "plot.html";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    handleMenuClose();
  };

  const handleShareAsImage = async () => {
    if (iframeRef.current) {
      const iframeDoc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      const plotDiv = iframeDoc.querySelector(".plotly-graph-div");
      // Get the Plotly graph from the iframe
      if (plotDiv) {
        try {
          // Convert Plotly graph to image
          const url = await Plotly.toImage(plotDiv, {
            format: "png",
            height: 1080,
            width: 1920,
          });
          console.log("Image URL: ", url);

          // Convert image URL to Blob
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], "plot.png", { type: "image/png" });

          // Share the image using the Web Share API
          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            await navigator.share({
              files: [file],
              title: "Check out this plot",
              text: "Here is a plot I generated",
            });
            console.log("Shared successfully");
          } else {
            alert("Sharing as image is not supported in your browser.");
          }
        } catch (imageError) {
          console.error("Error generating image", imageError);
        }
      } else {
        console.error("Plotly graph not found in iframe");
      }
    }
  };

  const handleShareAsHtml = () => {
    const blob = new Blob([plot_html], { type: "text/html" });
    const file = new File([blob], "plot.html", { type: "text/html" });

    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      navigator
        .share({
          files: [file],
          title: "Check out this plot",
          text: "Here is a plot I generated",
        })
        .catch((error) => console.log("Error sharing as HTML", error));
    } else {
      alert("Sharing as HTML is not supported in your browser.");
    }

    handleShareMenuClose();
  };

  const canShareOrDownloadImage = () => {
    if (iframeRef.current) {
      const iframeDoc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      return iframeDoc.querySelector(".plotly-graph-div") !== null;
    }
    return false;
  };

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShareClick = (event) => {
    setShareAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShareMenuClose = () => {
    setShareAnchorEl(null);
  };

  const [formOpen, setFormOpen] = useState(false);

  const handleOpenForm = () => {
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
  };

  const handleLimitReachedClose = () => {
    setLimitReachedOpen(false);
  };

  const handleLowBalanceClose = () => {
    setLowBalanceOpen(false);
  };

  const handleSignIn = async (authenticatedUser) => {
    try {
      setLoggedInUser(authenticatedUser);
      setIsUserSignedIn(true);
      setShowAuthenticator(false);
      updateRegisteredUser(true, authenticatedUser);
      // Store authentication status in localStorage
      localStorage.setItem("isUserSignedIn", "true");
    } catch (error) {
      console.error("Error during sign in:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setLoggedInUser(null);
      setIsUserSignedIn(false);
      // Clear any user-related data from localStorage if needed
      localStorage.setItem("isUserSignedIn", "false");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="sticky" sx={{ backgroundColor: "white" }}>
        <Toolbar
          sx={{
            "@media (max-width: 900px)": {
              paddingRight: "4.5px",
              paddingLeft: "4.5px",
            },

            "@media (min-width: 1600px)": {
              width: "100rem",
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        >
          {/* Logo and Company Name */}
          <Box
            className="logo-company_name"
            sx={{ marginLeft: "2rem" }}
            onClick={() => {
              navigate("/home");
            }}
          >
            <img src="/logo512.png" alt="Logo" className="logo" />
            <Typography variant="h5" component="div" className="company-name">
              agentik
            </Typography>
          </Box>
          {/* Navigation Links */}
          <Box
            sx={{
              flexGrow: 1,
              display: "none", // Hide when screen width is below 700px
              "@media (min-width: 700px)": {
                display: "flex", // Show when screen width is 700px or above
              },
              justifyContent: "flex-start",
              marginLeft: "3rem",
              columnGap: "2rem",
              height: "4rem",
              alignItems: "center",
            }}
          >
            <Link to="/home" style={{ textDecoration: "none" }}>
              <Typography variant="h10" className="nav-btn">
                Home
              </Typography>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography variant="h10" className="nav-btn btn-active">
                Capital Insights
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              gap: "1rem",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: "1rem",
                "@media (max-width: 900px)": {
                  marginLeft: "auto",
                },
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Free prompts used:{" "}
                {Math.min(
                  promptsUsed,
                  isUserSignedIn ? maxPromptsReg : maxPromptsUnreg
                )}
                /{isUserSignedIn ? maxPromptsReg : maxPromptsUnreg}
              </Typography>
            </Box> */}
            <Box
              sx={{
                display: "none", // Hide when screen width is below 700px
                "@media (min-width: 601px)": {
                  display: "block", // Show when screen width is 700px or above
                },
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleOpenForm}
                sx={{ width: "10rem" }}
              >
                Contact Sales
              </Button>
            </Box>
            <Box
              sx={{
                display: "none", // Hide when screen width is below 700px
                "@media (max-width: 600px)": {
                  display: "block", // Show when screen width is 700px or above
                },
              }}
            >
              <IconButton
                color="primary"
                onClick={() => navigate("/home")}
                aria-label="home"
                sx={{
                  border: "0.1rem solid #dddee1", // Add a 2px solid border
                  borderRadius: "0.5rem", // Rounded corners
                  padding: "0.5rem", // Add some padding to give space between icon and border
                }}
              >
                <HomeRoundedIcon
                  fontSize="medium"
                  sx={{
                    color: "#0082fb",
                  }}
                />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleOpenForm}
                aria-label="contact sales"
                sx={{
                  marginLeft: "4.5px",
                  border: "0.1rem solid #dddee1", // Add a 2px solid border
                  borderRadius: "0.5rem", // Rounded corners
                  padding: "0.5rem", // Add some padding to give space between icon and border
                }}
              >
                <ForwardToInboxRoundedIcon
                  fontSize="medium"
                  sx={{
                    color: "#0082fb",
                  }}
                />
              </IconButton>
              {!isUserSignedIn && (
                <IconButton
                  color="primary"
                  onClick={() => {
                    setAuthInitialState("signIn");
                    setShowAuthenticator(true);
                  }}
                  aria-label="login"
                  sx={{
                    marginLeft: "4.5px",
                    border: "0.1rem solid #dddee1", // Add a 2px solid border
                    borderRadius: "0.5rem", // Rounded corners
                    padding: "0.5rem", // Add some padding to give space between icon and border
                  }}
                >
                  <LoginOutlined
                    fontSize="medium"
                    sx={{
                      color: "#0082fb",
                    }}
                  />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                display: "none", // Hide by default
                "@media (min-width: 601px)": {
                  display: "flex", // Show when width is above 750px
                  gap: "1rem",
                },
              }}
            >
              {!isUserSignedIn && (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setAuthInitialState("signIn");
                      setShowAuthenticator(true);
                    }}
                    color="primary"
                  >
                    Sign in
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                    }}
                    onClick={() => {
                      setAuthInitialState("signUp");
                      setShowAuthenticator(true);
                    }}
                    color="primary"
                  >
                    Sign Up
                  </Button>
                </>
              )}{" "}
            </Box>
            {isUserSignedIn && (
              <UserProfileSideBar
                loggedInUser={loggedInUser}
                onSignOut={handleSignOut}
                pricePerPrompt={pricePerPrompt}
                userBalance={userBalance}
                setUserBalance={setUserBalance}
              />
            )}
            {showAuthenticator && (
              <AuthenticatorDialog
                open={showAuthenticator}
                onClose={() => setShowAuthenticator(false)}
                initialState={authInitialState}
                // onSignIn={handleSignIn}
                // setIsUserSignedIn={setIsUserSignedIn}
              />
            )}
          </Box>
          <ContactForm open={formOpen} handleClose={handleCloseForm} />
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          width: "100%", // Full width of the viewport
          padding: "1rem 0rem 1rem 1.5rem",
          "@media (min-width: 1200px)": {
            maxWidth: "100rem", // Maximum width of 100rem
            width: "100vw",
            maxHeight: "25.8125rem",
          },

          "@media (max-width: 900px)": {
            paddingRight: "0",
            paddingLeft: "0",
            paddingTop: "4.5px",
          },
        }}
      >
        <Grid
          container
          spacing={2}
          flexDirection={{ xs: "column", md: "row" }}
          sx={{
            "@media (max-width: 900px)": {
              marginLeft: "0",
              width: "100vw",
            },
          }}
        >
          <Grid
            item
            xs={12}
            md={8.5}
            sx={{
              "@media (max-width: 900px)": {
                paddingLeft: "4.5px !important",
                paddingRight: "0rem !important",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: { xs: "block", sm: "none" }, // Show only when screen width is below 600px
                }}
              >
                <Alert
                  severity="info"
                  sx={{ padding: "0", paddingLeft: "0.25rem" }}
                >
                  <Typography
                    variant="body2"
                    color="inherit"
                    marginTop="0.125rem"
                  >
                    Consider using a wider screen for an enhanced experience.
                  </Typography>
                </Alert>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h10" component="div">
                  What can I do for you?
                </Typography>
              </Grid>
              <Grid item xs={6} display="none">
                <Box
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Typography variant="body2" color="textSecondary">
                    Free prompts used:{" "}
                    {Math.min(
                      promptsUsed,
                      isUserSignedIn ? maxPromptsReg : maxPromptsUnreg
                    )}
                    /{isUserSignedIn ? maxPromptsReg : maxPromptsUnreg}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  "@media (max-width: 900px)": {
                    paddingRight: "4.5px !important",
                  },
                }}
              >
                <TextField
                  id="prompt"
                  className="prompt_box"
                  label="Enter your prompt and click VISUALIZE to plot chart or click CHAT to get direct answers"
                  // label={
                  //   <Typography
                  //     variant="body2"
                  //     style={{ whiteSpace: "normal", margin: 0, padding: 0  }}
                  //   >
                  //     Enter your prompt and click VISUALIZE to plot chart or
                  //     click CHAT to get direct answers
                  //   </Typography>
                  // }
                  placeholder=""
                  multiline
                  rows={3}
                  // maxRows={4}
                  fullWidth
                  value={prompt}
                  onChange={(e) => {
                    // if (agent > 1) {
                    //   setAgent(1);
                    // }
                    setPrompt(e.target.value);
                    if (e.target.value.length > 600) {
                      setWarning(true);
                    } else {
                      setWarning(false);
                    }
                  }}
                  inputRef={textFieldRef} // Attach the ref to the TextField
                  onClick={() => {
                    if (textFieldRef.current) {
                      textFieldRef.current.focus(); // Focus the TextField when clicked
                    }
                  }}
                  onFocus={() => {
                    if (textFieldRef.current) {
                      textFieldRef.current.focus(); // Ensure focus when the TextField is focused
                    }
                  }}
                  InputProps={{
                    sx: {
                      fontSize: "0.875rem", // Apply font size here
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "0.875rem", // Font size for label
                    },
                  }}
                />
                {warning && (
                  <Alert severity="error" sx={{ marginTop: "0.5rem" }}>
                    Prompt size exceeded, please shorten the length of your
                    prompt.
                  </Alert>
                )}
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    color: "#fff",
                  }}
                  variant="contained"
                  onClick={visualise}
                  disabled={!prompt.length || prompt.length > 600}
                >
                  Visualize
                  {/* {agent === 1
                    ? "Visualize"
                    : agent < 4
                    ? "Visualize"
                    : "Visualize"} */}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    color: "#fff",
                  }}
                  variant="contained"
                  onClick={chatToAgent}
                  disabled={!prompt.length || prompt.length > 600}
                >
                  Chat
                </Button>
              </Grid>
              {plot_html === null && (
                <Grid
                  item
                  xs={11.9}
                  lg={9}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      paddingRight: "1rem",
                      background: "white",
                      zIndex: 1,
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      color: "rgba(0, 0, 0, 0.6)",
                    }}
                  >
                    Try:
                  </Typography>
                  <ChipScroll onSelect={setPrompt} />
                </Grid>
              )}
              {plot_html && (
                <Grid
                  item
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <ButtonGroup
                    variant="outlined"
                    aria-label="Basic button group"
                  >
                    <Button
                      variant="outlined"
                      onClick={openInNewTab}
                      endIcon={<FullscreenIcon />}
                      className="fullscreen-button" // Add this class name
                      sx={{
                        padding: "0",
                        minWidth: "1rem",
                        width: "2.28125rem",
                      }}
                    ></Button>

                    <Button
                      variant="outlined"
                      onClick={handleDownloadClick}
                      endIcon={<DownloadIcon />}
                      className="download-button" // Add this class name
                      sx={{
                        padding: "0",
                        minWidth: "1rem",
                        width: "2.28125rem",
                      }}
                    ></Button>

                    <Button
                      variant="outlined"
                      onClick={handleShareClick}
                      startIcon={<ShareIcon />}
                      className="share-button" // Add this class name
                      sx={{
                        padding: "0",
                        minWidth: "1rem",
                        width: "2.28125rem",
                      }}
                    ></Button>
                  </ButtonGroup>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    {canShareOrDownloadImage() && (
                      <MenuItem onClick={downloadAsImage}>
                        Download as Image
                      </MenuItem>
                    )}
                    <MenuItem onClick={downloadAsHtml}>
                      Download as Interactive HTML
                    </MenuItem>
                  </Menu>
                  <Menu
                    anchorEl={shareAnchorEl}
                    open={Boolean(shareAnchorEl)}
                    onClose={handleShareMenuClose}
                  >
                    {canShareOrDownloadImage() && (
                      <MenuItem onClick={handleShareAsImage}>
                        Share as Image
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleShareAsHtml}>
                      Share as Interactive HTML
                    </MenuItem>
                  </Menu>
                </Grid>
              )}
              <div>
                <iframe
                  ref={iframeRef}
                  title="Plotly Plot"
                  style={{
                    display: "none",
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                  srcDoc={plot_html} // Load the HTML string into the iframe
                />
              </div>
              <Grid item xs={12}>
                {/* Conditionally render the Box containing the object if objectData is available */}
                {plot_html && (
                  <>
                    <Box className="plot_box">
                      <object
                        id="plotObject"
                        className="plot_object"
                        data={`data:text/html;charset=utf-8,${encodeURIComponent(
                          plot_html
                        )}`}
                        type="text/html"
                        aria-label="Plot visualization"
                      >
                        Your browser does not support embedded content.
                      </object>
                    </Box>
                    <Grid item xs={12}>
                      {/* Disclaimer text */}
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        align="center"
                        style={{ overflowX: "hidden" }}
                      >
                        Disclaimer: AI generated output. AI can make mistakes,
                        check important info.
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={3.5}
            sx={{
              height: {
                xs: "auto",
                md: "46.75rem",
              },
              "@media (max-width: 900px)": {
                paddingLeft: "13.5px !important",
                paddingRight: "4.5px !important",
              },
            }}
          >
            <InstructionsBox />
          </Grid>
        </Grid>
      </Container>
      {/* Render the spinner if loading */}
      {/* <Spinner loading={loading} /> */}
      <LoadingBar
        open={loading}
        setLoading={setLoading}
        progress={progress}
        setProgress={setProgress}
      />
      <LimitReachedDialog
        open={limitReachedOpen}
        onClose={handleLimitReachedClose}
        onSignIn={() => setShowAuthenticator(true)}
      />
      <LowBalanceDialog
        open={lowBalanceOpen}
        onClose={handleLowBalanceClose}
        setLowBalanceOpen={setLowBalanceOpen}
      />
    </ThemeProvider>
  );
};

export default MainView;
